import { graphql } from 'gatsby'
import { gridMap } from '../utils'
import { PageLayout } from '../components/Layout'
import { LinkboxCarousel } from '../components/LinkboxCarousel'
import { TextAndMediaLayout } from '../components/TextAndMedia'
import { Trustpilot } from '../components/Widgets'
import { NewsletterSubscribeBlock } from '../components/Newsletter'
import { PriceDisclaimer } from '../components/PriceDisclaimer'
import { Faq } from '../components/FAQ'
import { PaxCovidBanner } from '../components/PaxCovidBanner'
import Hero from '../components/Hero/Hero'

const HomePage = ({ data: { content } }) => {
  const {
    hero,
    covidBannerLayout,
    minibookReference,
    optionalSlider1,
    optionalSlider2,
    textAndMediaAward,
    sliderOurRoutes,
    textAndMedia,
    optionalTextAndMedia,
    optionalSlider3,
    trustpilotRef,
    additionalTrustpilotRef,
    optionalNewsletterSubscribeBlock,
    faqSection,
    sys,
  } = content.page

  return (
    <PageLayout>
      {hero?.contentRef && (
        <>
          <Hero {...hero.contentRef} miniBookReference={minibookReference} />

          {covidBannerLayout?.localisedContentReference?.header &&
            typeof sessionStorage !== 'undefined' &&
            sessionStorage.covidPaxBannerNotVisible !== 'true' && (
              <PaxCovidBanner
                {...covidBannerLayout?.localisedContentReference}
              />
            )}
        </>
      )}
      {optionalSlider1 && <LinkboxCarousel {...optionalSlider1} />}
      {optionalSlider2 && <LinkboxCarousel {...optionalSlider2} />}
      {sliderOurRoutes && <LinkboxCarousel {...sliderOurRoutes} />}
      {trustpilotRef && (
        <Trustpilot
          {...trustpilotRef}
          template="homePage"
          id={sys.id}
          item="trustpilotRef"
        />
      )}
      {optionalTextAndMedia && (
        <TextAndMediaLayout
          reverse
          mediaWidth={gridMap['two-thirds']}
          title={optionalTextAndMedia.title}
          text={optionalTextAndMedia.text}
          images={optionalTextAndMedia.imagesCollection.items}
          links={optionalTextAndMedia.linksCollection.items}
          {...optionalTextAndMedia}
        />
      )}
      <TextAndMediaLayout
        reverse
        mediaWidth={gridMap['two-thirds']}
        title={textAndMedia.title}
        text={textAndMedia.text}
        images={textAndMedia.imagesCollection.items}
        links={textAndMedia.linksCollection.items}
        {...textAndMedia}
      />
      {optionalSlider3 && <LinkboxCarousel {...optionalSlider3} />}
      {additionalTrustpilotRef && (
        <Trustpilot
          {...additionalTrustpilotRef}
          template="homePage"
          id={sys.id}
          item="additionalTrustpilotRef"
        />
      )}
      <TextAndMediaLayout
        mediaWidth={gridMap['two-thirds']}
        title={textAndMediaAward.title}
        text={textAndMediaAward.text}
        images={textAndMediaAward.imagesCollection.items}
        links={textAndMediaAward.linksCollection.items}
        {...textAndMediaAward}
      />
      {optionalNewsletterSubscribeBlock && (
        <NewsletterSubscribeBlock
          {...optionalNewsletterSubscribeBlock}
          reverse
        />
      )}
      {faqSection?.itemsCollection?.items &&
        faqSection?.itemsCollection?.items.length !== 0 && (
          <Faq
            {...faqSection.itemsCollection}
            heading={faqSection?.heading}
            urlSlug={faqSection?.urlSlug?.slug}
            defaultExpanded={faqSection?.defaultExpanded ?? false}
          />
        )}
      <PriceDisclaimer />
    </PageLayout>
  )
}

export const query = graphql`
  query HomePage($id: String!, $locale: String!, $preview: Boolean = false) {
    content: contentful {
      page: homePage(id: $id, locale: $locale, preview: $preview) {
        sys {
          id
        }
        hero: heroReference {
          ... on contentful_PaxHeroLayout {
            contentRef {
              ...PaxHeroContent
            }
          }
        }
        covidBannerLayout {
          localisedContentReference {
            ...CovidBanner
          }
        }
        minibookReference {
          ...Minibook
        }
        optionalNewsletterSubscribeBlock {
          ...NewsletterSubscribeBlock
        }
        optionalSlider1 {
          ...LinkboxCarousel
        }
        optionalSlider2 {
          ...LinkboxCarousel
        }
        optionalSlider3 {
          ...LinkboxCarousel
        }
        sliderOurRoutes {
          ...LinkboxCarousel
        }
        trustpilotRef {
          ...Trustpilot
        }
        additionalTrustpilotRef {
          ...Trustpilot
        }
        textAndMediaAward {
          ...TextAndMediaContent
        }
        textAndMedia {
          ...TextAndMediaContent
        }
        optionalTextAndMedia {
          ...TextAndMediaContent
        }
        optionalNewsletterSubscribeBlock {
          ...NewsletterSubscribeBlock
        }
        faqSection {
          ...FaqFragment
        }
      }
    }
  }
`

export default HomePage
