import { graphql } from 'gatsby'

import { LinkboxCarousel } from '../components/LinkboxCarousel'
import { TextAndMediaLayout } from '../components/TextAndMedia'
import { NewsletterSubscribeBlock } from '../components/Newsletter'
import { PageLayout } from '../components/Layout'
import { TourOperatorWidget, Trustpilot } from '../components/Widgets'
import { PriceDisclaimer } from '../components/PriceDisclaimer'
import { Faq } from '../components/FAQ'
import { PaxCovidBanner } from '../components/PaxCovidBanner'
import Hero from '../components/Hero/Hero'

const FerryCrossingOnboardExperienceOverviewPage = ({ data: { content } }) => {
  const {
    hero,
    covidBannerLayout,
    miniBookReference,
    tourOperatorWidget,
    textAndMediaTitle,
    textAndMediaContentReference,
    optionalTextAndMedia1ContentReference,
    linkboxSliderReference,
    optionalLinkboxSlider1Reference,
    optionalLinkboxSlider2Reference,
    optionalTextAndImage1ContentReference,
    optionalTextAndImage2ContentReference,
    optionalTextAndImage3ContentReference,
    textAndImageContentReference,
    trustpilotRef,
    additionalTrustpilotRef,
    optionalNewsletterSubscribeBlock,
    faqSection,
    sys,
  } = content.page

  return (
    <PageLayout>
      {hero?.contentRef && (
        <>
          <Hero {...hero.contentRef} miniBookReference={miniBookReference} />

          {covidBannerLayout?.localisedContentReference?.header &&
            typeof sessionStorage !== 'undefined' &&
            sessionStorage.covidPaxBannerNotVisible !== 'true' && (
              <PaxCovidBanner
                {...covidBannerLayout?.localisedContentReference}
              />
            )}
        </>
      )}
      {tourOperatorWidget && <TourOperatorWidget {...tourOperatorWidget} />}
      <TextAndMediaLayout
        mediaWidth={{ md: 8 }}
        reverse
        makeTitleAnH1
        title={textAndMediaTitle || textAndMediaContentReference.title}
        text={textAndMediaContentReference.text}
        images={textAndMediaContentReference.imagesCollection.items}
        links={textAndMediaContentReference.linksCollection.items}
        {...textAndMediaContentReference}
      />
      {trustpilotRef && (
        <Trustpilot
          {...trustpilotRef}
          template="ferryCrossingOnboardExperienceOverviewPage"
          id={sys.id}
          item="trustpilotRef"
        />
      )}
      {optionalTextAndMedia1ContentReference && (
        <TextAndMediaLayout
          mediaWidth={{ md: 8 }}
          title={optionalTextAndMedia1ContentReference.title}
          text={optionalTextAndMedia1ContentReference.text}
          images={optionalTextAndMedia1ContentReference.imagesCollection.items}
          links={optionalTextAndMedia1ContentReference.linksCollection.items}
          {...optionalTextAndMedia1ContentReference}
        />
      )}
      {linkboxSliderReference && (
        <LinkboxCarousel {...linkboxSliderReference} />
      )}
      {optionalLinkboxSlider1Reference && (
        <LinkboxCarousel {...optionalLinkboxSlider1Reference} />
      )}
      {optionalLinkboxSlider2Reference && (
        <LinkboxCarousel {...optionalLinkboxSlider2Reference} />
      )}
      {additionalTrustpilotRef && (
        <Trustpilot
          {...additionalTrustpilotRef}
          template="ferryCrossingOnboardExperienceOverviewPage"
          id={sys.id}
          item="additionalTrustpilotRef"
        />
      )}
      {optionalTextAndImage1ContentReference && (
        <TextAndMediaLayout
          mediaWidth={{ md: 8 }}
          reverse
          title={optionalTextAndImage1ContentReference.title}
          text={optionalTextAndImage1ContentReference.text}
          images={optionalTextAndImage1ContentReference.imagesCollection.items}
          links={optionalTextAndImage1ContentReference.linksCollection.items}
          {...optionalTextAndImage1ContentReference}
        />
      )}
      {optionalTextAndImage2ContentReference && (
        <TextAndMediaLayout
          mediaWidth={{ md: 8 }}
          title={optionalTextAndImage2ContentReference.title}
          text={optionalTextAndImage2ContentReference.text}
          images={optionalTextAndImage2ContentReference.imagesCollection.items}
          links={optionalTextAndImage2ContentReference.linksCollection.items}
          {...optionalTextAndImage2ContentReference}
        />
      )}
      {optionalTextAndImage3ContentReference && (
        <TextAndMediaLayout
          mediaWidth={{ md: 8 }}
          reverse={optionalTextAndImage2ContentReference}
          title={optionalTextAndImage3ContentReference.title}
          text={optionalTextAndImage3ContentReference.text}
          images={optionalTextAndImage3ContentReference.imagesCollection.items}
          links={optionalTextAndImage3ContentReference.linksCollection.items}
          {...optionalTextAndImage3ContentReference}
        />
      )}
      <TextAndMediaLayout
        mediaWidth={{ md: 8 }}
        title={textAndImageContentReference.title}
        text={textAndImageContentReference.text}
        images={textAndImageContentReference.imagesCollection.items}
        links={textAndImageContentReference.linksCollection.items}
        {...textAndImageContentReference}
      />
      {optionalNewsletterSubscribeBlock && (
        <NewsletterSubscribeBlock
          {...optionalNewsletterSubscribeBlock}
          reverse
        />
      )}
      {faqSection?.itemsCollection?.items &&
        faqSection?.itemsCollection?.items.length !== 0 && (
          <Faq
            {...faqSection.itemsCollection}
            heading={faqSection?.heading}
            urlSlug={faqSection?.urlSlug?.slug}
            defaultExpanded={faqSection?.defaultExpanded ?? false}
          />
        )}
      <PriceDisclaimer />
    </PageLayout>
  )
}

export const query = graphql`
  query FerryCrossingOnboardExperienceOverviewPage(
    $id: String!
    $locale: String!
  ) {
    content: contentful {
      page: ferryCrossingOnboardExperienceOverviewPage(
        id: $id
        locale: $locale
      ) {
        sys {
          id
        }
        hero: heroReference {
          ... on contentful_PaxHeroLayout {
            contentRef {
              ...PaxHeroContent
            }
          }
        }
        covidBannerLayout {
          localisedContentReference {
            ...CovidBanner
          }
        }
        miniBookReference {
          ...Minibook
          ...MinibookProductList
        }
        tourOperatorWidget {
          ...TourOperatorWidget
        }
        textAndMediaTitle
        textAndMediaContentReference {
          ...TextAndMediaContent
        }
        optionalTextAndMedia1ContentReference {
          ...TextAndMediaContent
        }
        linkboxSliderReference {
          ...LinkboxCarousel
        }
        optionalLinkboxSlider1Reference {
          ...LinkboxCarousel
        }
        optionalLinkboxSlider2Reference {
          ...LinkboxCarousel
        }
        optionalTextAndImage1ContentReference {
          ...TextAndMediaContent
        }
        optionalTextAndImage2ContentReference {
          ...TextAndMediaContent
        }
        optionalTextAndImage3ContentReference {
          ...TextAndMediaContent
        }
        textAndImageContentReference {
          ...TextAndMediaContent
        }
        trustpilotRef {
          ...Trustpilot
        }
        additionalTrustpilotRef {
          ...Trustpilot
        }
        optionalNewsletterSubscribeBlock {
          ...NewsletterSubscribeBlock
        }
        faqSection {
          ...FaqFragment
        }
      }
    }
  }
`

export default FerryCrossingOnboardExperienceOverviewPage
