import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { media } from '@dfds-ui/theme'
import { PageLayout } from '../components/Layout'
import { PaxProductCard } from '../components/PaxProductCard'
import { PaxTimeTable } from '../components/PaxTimeTable'
import { TextAndMediaLayout } from '../components/TextAndMedia'
import { isContentfulType } from '../utils'
import { LinkboxCarousel } from '../components/LinkboxCarousel'
import { NewsletterSubscribeBlock } from '../components/Newsletter'
import { Trustpilot } from '../components/Widgets'
import { PriceDisclaimer } from '../components/PriceDisclaimer'
import { PageContentTextSection } from '../components/PageContentTextSection'
import { Faq } from '../components/FAQ'
import { PaxCovidBanner } from '../components/PaxCovidBanner'
import Hero from '../components/Hero/Hero'
import {
  InfoBlockLoyaltyTiers,
  InfoBlockLoyaltyWhy,
} from '../components/InfoBlockLoyalty'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;

  ${media.greaterThan('l')`
    flex-direction: row;
  `}

  > div {
    width: 100%;
    margin-bottom: 40px;

    ${media.greaterThan('l')`
      &[md="8"], &[lg="8"] {
        width: calc(66.66% - 20px);
      }
      &[md="4"], &[lg="4"] {
        width: calc(33.33% - 26.66px);
      }
    `}
  }
`

const FerryCrossingProductPage = ({ data: { content } }) => {
  const {
    hero,
    covidBannerLayout,
    miniBookReference,
    optionalNewsletterSubscribeBlock,
    productCardReference,
    timeTableReference,
    textAndImage1ContentReference,
    textAndImage2ContentReference,
    linkboxSlider1Reference,
    linkboxSlider1Title,
    linkboxSlider2Reference,
    linkboxSlider2Title,
    linkboxSlider3Reference,
    linkboxSlider3Title,
    awardTextAndImageContentReference,
    trustpilotRef,
    additionalTrustpilotRef,
    termsAndConditionsReference,
    optionalTextAndMediaContentReference,
    faqSection,
    sys,
  } = content.page
  const pagePath = typeof window !== 'undefined' && window.location.pathname

  return (
    <PageLayout>
      {hero?.contentRef && (
        <>
          <Hero {...hero.contentRef} miniBookReference={miniBookReference} />

          {covidBannerLayout?.localisedContentReference?.header &&
            typeof sessionStorage !== 'undefined' &&
            sessionStorage.covidPaxBannerNotVisible !== 'true' && (
              <PaxCovidBanner
                {...covidBannerLayout?.localisedContentReference}
              />
            )}
        </>
      )}
      <Wrapper>
        <PaxProductCard width={{ md: 8 }} {...productCardReference} />
        {pagePath === '/en-gb/passenger-ferries/loyalty-scheme-a' && (
          <InfoBlockLoyaltyTiers />
        )}
        {pagePath === '/en-gb/passenger-ferries/loyalty-scheme-b' && (
          <InfoBlockLoyaltyWhy />
        )}
        {pagePath === '/en-gb/passenger-ferries/loyalty-scheme-c' && (
          <InfoBlockLoyaltyWhy />
        )}
        {isContentfulType(
          timeTableReference.__typename,
          'contentful_PaxTimeTable',
        ) && <PaxTimeTable {...timeTableReference} width={{ md: 4 }} />}
        {isContentfulType(
          timeTableReference.__typename,
          'contentful_TextAndMediaContent',
        ) && (
          <TextAndMediaLayout
            blockWidth={'one-third'}
            title={timeTableReference.title}
            text={timeTableReference.text}
            images={timeTableReference.imagesCollection.items}
            links={timeTableReference.linksCollection.items}
            lightboxes={timeTableReference.lightboxesCollection.items}
            {...timeTableReference}
          />
        )}
      </Wrapper>
      {trustpilotRef && (
        <Trustpilot
          {...trustpilotRef}
          template="ferryCrossingProductPage"
          id={sys.id}
          item="trustpilotRef"
        />
      )}
      <TextAndMediaLayout
        mediaWidth={{ md: 8 }}
        reverse
        title={textAndImage1ContentReference.title}
        text={textAndImage1ContentReference.text}
        images={textAndImage1ContentReference.imagesCollection.items}
        links={textAndImage1ContentReference.linksCollection.items}
        {...textAndImage1ContentReference}
      />
      {termsAndConditionsReference && (
        <PageContentTextSection {...termsAndConditionsReference} />
      )}
      {optionalTextAndMediaContentReference && (
        <TextAndMediaLayout
          mediaWidth={{ md: 8 }}
          reverse
          title={optionalTextAndMediaContentReference.title}
          text={optionalTextAndMediaContentReference.text}
          images={optionalTextAndMediaContentReference.imagesCollection.items}
          links={optionalTextAndMediaContentReference.linksCollection.items}
          {...optionalTextAndMediaContentReference}
        />
      )}
      {additionalTrustpilotRef && (
        <Trustpilot
          {...additionalTrustpilotRef}
          template="ferryCrossingProductPage"
          id={sys.id}
          item="additionalTrustpilotRef"
        />
      )}
      <LinkboxCarousel
        {...linkboxSlider1Reference}
        title={linkboxSlider1Title || linkboxSlider1Reference.title}
      />
      {((linkboxSlider2Title && linkboxSlider2Reference) ||
        linkboxSlider2Reference?.title) && (
        <LinkboxCarousel
          {...linkboxSlider2Reference}
          title={linkboxSlider2Title || linkboxSlider2Reference.title}
        />
      )}
      {((linkboxSlider3Title && linkboxSlider3Reference) ||
        linkboxSlider3Reference?.title) && (
        <LinkboxCarousel
          {...linkboxSlider3Reference}
          title={linkboxSlider3Title || linkboxSlider3Reference.title}
        />
      )}
      <TextAndMediaLayout
        mediaWidth={{ md: 8 }}
        title={textAndImage2ContentReference.title}
        text={textAndImage2ContentReference.text}
        images={textAndImage2ContentReference.imagesCollection.items}
        links={textAndImage2ContentReference.linksCollection.items}
        {...textAndImage2ContentReference}
      />
      <TextAndMediaLayout
        mediaWidth={{ md: 8 }}
        reverse
        title={awardTextAndImageContentReference.title}
        text={awardTextAndImageContentReference.text}
        images={awardTextAndImageContentReference.imagesCollection.items}
        links={awardTextAndImageContentReference.linksCollection.items}
        {...awardTextAndImageContentReference}
      />
      {optionalNewsletterSubscribeBlock && (
        <NewsletterSubscribeBlock {...optionalNewsletterSubscribeBlock} />
      )}
      {faqSection?.itemsCollection?.items &&
        faqSection?.itemsCollection?.items.length !== 0 && (
          <Faq
            {...faqSection.itemsCollection}
            heading={faqSection?.heading}
            urlSlug={faqSection?.urlSlug?.slug}
            defaultExpanded={faqSection?.defaultExpanded ?? false}
          />
        )}
      <PriceDisclaimer />
    </PageLayout>
  )
}

export const query = graphql`
  query FerryCrossingProductPage(
    $id: String!
    $locale: String!
    $preview: Boolean = false
  ) {
    content: contentful {
      page: ferryCrossingProductPage(
        id: $id
        locale: $locale
        preview: $preview
      ) {
        sys {
          id
        }
        hero: heroReference {
          ... on contentful_PaxHeroLayout {
            contentRef {
              ...PaxHeroContent
            }
          }
        }
        covidBannerLayout {
          localisedContentReference {
            ...CovidBanner
          }
        }
        miniBookReference {
          ...Minibook
          ...MinibookProductList
        }
        productCardReference {
          ...PaxProductCard
        }
        timeTableReference {
          __typename
          ...PaxTimeTable
          ...TextAndMediaContent
        }
        trustpilotRef {
          ...Trustpilot
        }
        textAndImage1ContentReference {
          ...TextAndMediaContent
        }
        termsAndConditionsReference {
          ...PageContentTextSection
        }
        optionalTextAndMediaContentReference {
          ...TextAndMediaContent
        }
        additionalTrustpilotRef {
          ...Trustpilot
        }
        linkboxSlider1Title
        linkboxSlider1Reference {
          ...LinkboxCarousel
        }
        linkboxSlider2Title
        linkboxSlider2Reference {
          ...LinkboxCarousel
        }
        linkboxSlider3Title
        linkboxSlider3Reference {
          ...LinkboxCarousel
        }
        textAndImage2ContentReference {
          ...TextAndMediaContent
        }
        awardTextAndImageContentReference {
          ...TextAndMediaContent
        }
        optionalNewsletterSubscribeBlock {
          ...NewsletterSubscribeBlock
        }
        faqSection {
          ...FaqFragment
        }
      }
    }
  }
`

export default FerryCrossingProductPage
